<template>
  <div>
    <stripe-element-payment
      v-if="!loadingStripe"
      ref="paymentRef"
      :pk="pk"
      :elements-options="elementsOptions"
      :confirm-params="confirmParams"
    />
    <div class="w-100 d-flex justify-content-end">
      <b-button
        v-if="!loadingStripe"
        @click="pay"
        variant="primary"
        class="mt-4"
        >Paga adesso</b-button
      >
      <b-button v-else class="mt-4" variant="primary" disabled
        >Paga adesso</b-button
      >
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe"
import AxiosService from "@/axiosServices/AxiosService"
export default {
  components: {
    StripeElementPayment,
  },
  props: {
    idFattura: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingStripe: true,
      pk:
        "pk_test_51NdumIHYQKebBvncNgbGb1lr0Hx5KYElh1UHh0zuoGktia97M9ingey77QPDO1n6R9XScvX95L5yT0c8yoP21nif008a8t9SY9",
      elementsOptions: {
        appearance: {},
        clientSecret: "",
      },
      confirmParams: {
        return_url: `http://localhost:8080/stripe/success/${this.idFattura}`,
      },
    }
  },
  mounted() {
    this.getPaymentSecret()
  },
  methods: {
    async getPaymentSecret() {
      this.loadingStripe = true
      const service = new AxiosService(
        `Plugin/StripePayments/GetPaymentIntent/${this.idFattura}`
      )

      service
        .read()
        .then((res) => {
          this.elementsOptions.clientSecret = res
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loadingStripe = false
        })
    },

    pay() {
      this.$refs.paymentRef.submit()
    },
  },
}
</script>
