<template>
  <div class="content-wrapper user-page">
    <h1 class="mb-5">Licence Manager</h1>

    <b-alert v-if="totAmountUnpaidInvoices > 0" show variant="danger">
      <h4 class="alert-heading">
        Ops! Ci risultano fatture non saldate per un importo di
        {{
          totAmountUnpaidInvoices.toLocaleString("it", {
            style: "currency",
            currency: "EUR",
          })
        }}.
      </h4>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <div
          class="d-flex justify-content-center align-items-center "
          style="max-width: 70px; height: auto;"
        >
          <img
            src="@/assets/images/crying-face.gif"
            style="width:100% ; height: 100%;"
          />
        </div>
        <div class="ml-3">
          Ti ricordiamo che puoi pagare in modo semplice ed immediato
          direttamente dalla sezione fatture utilizzando una carta di credito o
          un conto bancario
        </div>
      </div>
    </b-alert>

    <b-card class="mb-4">
      <b-table
        hover
        :items="items_client"
        :fields="fields_client"
        sort-icon-left
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        localSortBy="attivo"
        localSortDesc="true"
      >
        <template #cell(apiEndpoint)="row">
          <code v-if="row.item.apiEndpoint"
            >https://api-{{ row.item.apiEndpoint }}.whappy.it</code
          >
          <code v-else> --- </code>
        </template>
        <template #cell(TotalPrice)="row">
          {{ row.item.TotalPrice }} €
        </template>
        <template #cell(attivo)="row">
          <b-icon-check-circle-fill
            class="ml-3"
            v-if="row.item.attivo"
            style="color: green"
          ></b-icon-check-circle-fill>
          <b-icon-dash-circle-fill
            class="ml-3"
            v-else
            style="color: grey"
          ></b-icon-dash-circle-fill>
        </template>
        <template #cell(billing)="row">
          <span
            style="font-weight: bolder"
            class="ml-3"
            role="button"
            v-b-toggle.sidebar-billing
            @click="
              currentItem = row.item
              tmpItem = JSON.parse(JSON.stringify(row.item))
            "
            >$</span
          >

          <!-- <b-iconstack
            class="ml-3"
            role="button"
            v-b-toggle.sidebar-billing
            @click="
              currentItem = row.item;
              tmpItem = JSON.parse(JSON.stringify(row.item));
            "
          >
            <b-icon-cash></b-icon-cash>
          </b-iconstack> -->
        </template>
        <template #cell(actions)="row">
          <b-icon-file-earmark
            role="button"
            v-b-toggle.sidebar-invoices
          ></b-icon-file-earmark>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>

    <b-modal
      v-model="showDeleteModal"
      title="Attenzione"
      ok-title="Conferma"
      cancel-title="Annulla"
      @hidden="resetSidebar_client"
      @ok="deleteItem(currentItem.id)"
      >Sei sicuro di voler rimuovere
      <span v-if="currentItem" style="font-weight: bold">
        {{ currentItem.name }}</span
      >
      dalla lista clienti?
    </b-modal>

    <b-sidebar id="sidebar-invoices" class="right-sidebar" right shadow lazy>
      <div
        v-if="loadingGetInvoices"
        class="w-100 d-flex justify-content-center align-items-center p-5 m-5"
      >
        <b-spinner type="grow"></b-spinner>
      </div>
      <div v-else class="content-wrapper my-3 mx-1">
        <b-table
          hover
          :items="invoicesItems"
          :fields="fields_invoices"
          sort-icon-left
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          localSortBy="attivo"
          localSortDesc="true"
        >
          <template #cell(fileUrl)="row">
            <a :href="row.item.fileUrl" target="_blank"
              ><b-icon-file-earmark></b-icon-file-earmark
            ></a>
          </template>
          <template #cell(invoiceDate)="row">
            {{
              new Date(row.item.invoiceDate).toLocaleDateString("it", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </template>
          <template #cell(isPaid)="row">
            <b-icon-check-circle-fill
              class="ml-3"
              v-if="row.item.isPaid"
              style="color: green"
            ></b-icon-check-circle-fill>
            <b-icon-dash-circle-fill
              class="ml-3"
              v-else
              style="color: maroon"
            ></b-icon-dash-circle-fill>
          </template>
          <template #cell(actions)="row">
            <b-icon-cash-stack
              v-if="!row.item.isPaid"
              role="button"
              v-b-toggle.sidebar-stripe
              @click="tempFattura = row"
            ></b-icon-cash-stack>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalInvoicesRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </div>
    </b-sidebar>

    <b-sidebar id="sidebar-utenti" class="right-sidebar" right shadow lazy>
      <div class="content-wrapper my-3 mx-1">
        <h4>Utenti</h4>
        <b-table
          hover
          :items="tableUtentiItems"
          :fields="fields_tableUtenti"
          sort-icon-left
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          localSortBy="attivo"
          localSortDesc="true"
        ></b-table>
      </div>
    </b-sidebar>

    <b-sidebar
      id="sidebar-billing"
      class="right-sidebar"
      right
      shadow
      lazy
      @shown="loadBillingData(currentItem)"
      @hidden="resetSidebar_billing"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentItem">{{ currentItem.name }}</h4>

        <b-row>
          <b-col cols="8">
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="calendar"></b-icon>
              </b-input-group-prepend>
              <b-form-select
                @change="loadBillingData(currentItem)"
                v-model="year_selected"
                :options="year_options"
              ></b-form-select>
            </b-input-group>
          </b-col>
        </b-row>
        <b-overlay
          :show="overlay_billing"
          rounded="sm"
          class="overlay-placeholder"
        >
          <b-table hover :items="items_billing" :fields="fields_billing">
            <template #cell(mese)="row">
              {{ meseItaliano[row.item.mese] }}
            </template>
            <template #cell(apiUsage)="row">
              {{ (row.item.ApiCall / row.item.ApiTot).toFixed(2) }} %
            </template>
            <template #cell(PriceForUser)="row"
              >€ {{ row.item.PriceForUser }}</template
            >
            <template #cell(TotalPrice)="row"
              >€ {{ row.item.TotalPrice }}</template
            >
            <template #cell(sidebar-users)="row">
              <b-icon-person-fill
                role="button"
                v-b-toggle.sidebar-utenti-billing
                @click="getUserInMonth(row.item)"
              ></b-icon-person-fill>
            </template>
          </b-table>
        </b-overlay>
      </div>
    </b-sidebar>
    <b-sidebar
      id="sidebar-utenti-billing"
      class="right-sidebar"
      right
      shadow
      lazy
    >
      <div class="content-wrapper my-3 mx-1">
        <b-overlay
          :show="overlay_UsersInMonth"
          rounded="sm"
          class="overlay-placeholder"
          ><h4>Lista utenti {{ meseItaliano[currentMonth] }}</h4>
          <b-table
            hover
            :items="tableUtentiItemsInMonth"
            :fields="fields_tableUtentiInMonth"
            sort-icon-left
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            localSortBy="attivo"
            localSortDesc="true"
          ></b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsersInMonthRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="mt-4 mb-1"
          ></b-pagination>
        </b-overlay>
      </div>
    </b-sidebar>
    <b-sidebar id="sidebar-stripe" class="right-sidebar" right shadow>
      <div v-if="tempFattura" class="px-4 py-5">
        <b-card style="color:#6d6e78">
          <div class="d-flex flex-column">
            <h4>Dettagli fattura</h4>
            <span
              >Numero fattura:
              <strong>{{ tempFattura.item.invoiceNumber }}</strong></span
            >
            <span
              >Data fattura:
              <strong>{{
                new Date(tempFattura.item.invoiceDate).toLocaleDateString(
                  "it",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )
              }}</strong></span
            >
            <span
              >Totale: <strong>{{ tempFattura.item.total }} € </strong></span
            >
          </div>
        </b-card>

        <StripePayment
          class="mt-4"
          :idFattura="
            tempFattura.item.id || tempFattura.item.Id || tempFattura.item.ID
          "
        ></StripePayment>
      </div>
      <div v-else>
        Nessuna fattura selezionata
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import axios from "axios"
import AxiosService from "../axiosServices/AxiosService"
import Doughnut from "../components/DoughnutChart.vue"
import StripePayment from "@/components/stripe/StripePayment"

export default {
  name: "LicenceManager",
  service: null,
  components: {
    Doughnut,
    StripePayment,
  },
  created() {
    this.service = new AxiosService("LicenceManager")
  },
  mounted() {
    this.getInvoices()
    try {
      axios
        .get(
          "https://api-fdt.whappy.it/api/Licence/GetUserCurrentMonth/ENTERPRISE"
        )
        .then((res) => {
          this.items_client[0].TotalPrice = res.data.TotalPrice
          this.items_client[0].Users = res.data.Users
          this.tableUtentiItems = res.data.UsersList

          client.TotalPrice = res.data.TotalPrice
          client.Users = res.data.Users
        })
    } catch {
      null
    }

    this.items_client.unshift(client)
    this.pieData.names.unshift(client.name)
    this.pieData.prices.unshift(client.TotalPrice || 0)
    this.pieData.nUsers.unshift(client.Users)

    let currentLicenceIndex = this.pieData.licences.indexOf(client.licenceType)

    if (currentLicenceIndex == -1) {
      this.pieData.licences.unshift(client.licenceType)
      this.pieData.nLicences.unshift(1)
    } else {
      this.pieData.nLicences[currentLicenceIndex]++
    }

    client.attivo ? this.pieData.actives++ : this.pieData.nonActives++
  },
  data() {
    return {
      pieData: {
        names: [],
        prices: [],
        nUsers: [],
        licences: [],
        nLicences: [],
        actives: [],
        nonActives: [],
      },

      currentMonth: "",
      meseItaliano: {
        January: "Gennaio",
        February: "Febbraio",
        March: "Marzo",
        April: "Aprile",
        May: "Maggio",
        June: "Giugno",
        July: "Luglio",
        August: "Agosto",
        September: "Settembre",
        October: "Ottobre",
        November: "Novembre",
        December: "Dicembre",
      },

      tableUtentiItems: [],
      tableUtentiItemsInMonth: [],
      fields_tableUtenti: [
        { key: "name", label: "Nome", sortable: true },
        { key: "company", label: "Company", sortable: true },
        { key: "role", label: "Ruolo", sortable: true },
      ],
      fields_tableUtentiInMonth: [
        { key: "name", label: "Nome", sortable: true },
        { key: "company", label: "Company", sortable: true },
        { key: "role", label: "Ruolo", sortable: true },
      ],
      overlay_billing: false,
      overlay_UsersInMonth: false,

      showDeleteModal: false,
      isLoading: false,

      currentItem: null,
      tmpItem: { licenceType: null },

      year_selected: new Date().getFullYear(),
      year_options: [
        { value: null, text: "Select year to show" },
        { value: 2023, text: "2023" },
      ],

      licenceType_selected: null,
      licenceType_options: [
        { value: null, text: "Select licence type" },
        { value: "LITE", text: "Lite" },
        { value: "PLUS", text: "Plus" },
        { value: "PROFESSIONAL", text: "Professional" },
        { value: "ENTERPRISE", text: "Enterprise" },
      ],
      filter: "",
      currentPage: 1,
      perPage: 10,
      items_client: [
        {
          name: "Fattoria dei Talenti",
          licenceType: "ENTERPRISE",
          apiEndpoint: "fdt",
          TotalPrice: 0,
          Users: 0,
          attivo: true,
          UsersList: [],
        },
      ],
      fields_client: [
        { key: "name", label: "Name", sortable: true },
        { key: "licenceType", label: "Licence type", sortable: true },
        { key: "apiEndpoint", label: "API endpoint", sortable: true },
        { key: "TotalPrice", label: "Current month Price", sortable: true },
        { key: "Users", label: "Current month Users", sortable: true },
        { key: "attivo", label: "Active", sortable: true },
        { key: "billing", label: "Billing" },
        { key: "actions", label: "Invoice" },
      ],
      items_billing: [],
      fields_billing: [
        { key: "mese", label: "Month" },
        { key: "Users", label: "N° users" },
        { key: "apiUsage", label: "API usage" },
        { key: "PriceForUser", label: "Price for user" },
        { key: "TotalPrice", label: "Total price" },
        { key: "sidebar-users", label: "Users" },
      ],
      invoicesItems: [],
      fields_invoices: [
        { key: "invoiceNumber", label: "Numero" },
        { key: "invoiceDate", label: "Data" },
        { key: "total", label: "Totale" },
        { key: "isPaid", label: "Saldata" },
        { key: "fileUrl", label: "Fattura" },
        { key: "actions", label: "Paga ora" },
      ],
      tempFattura: null,
      loadingGetInvoices: false,
      totAmountUnpaidInvoices: 0,
    }
  },
  methods: {
    getInvoices() {
      this.loadingGetInvoices = true
      const service = new AxiosService(`Plugin/AutoInvoice/GetInvoices`)

      service
        .read()
        .then((res) => {
          this.invoicesItems = res
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.checkUnpaidInvoices()
          this.loadingGetInvoices = false
        })
    },
    checkUnpaidInvoices() {
      console.log("trig check unpaid invoices ")
      this.totAmountUnpaidInvoices = 0
      this.invoicesItems.forEach((invoice) => {
        console.log(
          "🚀 ~ file: FDTLicenceManager.vue:522 ~ this.invoicesItems.forEach ~ invoice:",
          invoice
        )
        if (!invoice.isPaid) {
          console.log("UNPAID!!! ")
          this.totAmountUnpaidInvoices += invoice.total
        }
      })
    },
    getUserInMonth(item) {
      console.log(
        "🚀 ~ file: FDTLicenceManager.vue:335 ~ getUserInMonth ~ item:",
        item
      )
      this.overlay_UsersInMonth = true
      this.tableUtentiItemsInMonth.splice(0)
      this.currentMonth = ""
      const service = new AxiosService(
        `Licence/GetUserInMonth/${this.year_selected}/${item.meseInt}/${this.currentItem.licenceType}`
      )
      this.currentMonth = item.mese
      service
        .read()
        .then((res) => {
          this.tableUtentiItemsInMonth = res.UsersList
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.overlay_UsersInMonth = false
        })
    },
    loadBillingData(currentItem) {
      this.overlay_billing = true

      axios
        .get(
          "https://api-" +
            currentItem.apiEndpoint +
            ".whappy.it/api/Licence/GetUserByYear/" +
            this.year_selected +
            "/" +
            currentItem.licenceType
        )
        .then((res) => {
          if (res.data.length > 0) {
            this.items_billing = res.data
          }
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
        .finally(() => {
          this.overlay_billing = false
        })
    },
    saveClient() {
      this.isLoading = true
      let item = { ...this.tmpItem }

      if (item.id) {
        this.service
          .putCustomEndpoint("Licence/UpdateClient/" + item.id, "", item)
          .then((res) => {
            if (res.id == item.id) {
              Object.keys(this.currentItem).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentItem[element] = item[element]
                }
              })
              this.$root.$emit("bv::toggle::collapse", "sidebar-client")
              this.$successToast()
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.service
          .postCustomEndpoint("Licence/AddClient", "", this.tmpItem)
          .then((res) => {
            if (res.id) {
              this.items_client.push(item)
              this.$root.$emit("bv::toggle::collapse", "sidebar-client")
              this.$successToast()
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    deleteItem(id) {
      this.service
        .deleteCustomEndpoint("Licence/DeleteClient", id)
        .then((res) => {
          if (res) {
            this.items_client = this.items_client.filter(
              (currentItem) => id !== currentItem.id
            )
            this.$successToast()
          } else {
            this.$errorToast()
            return false
          }
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
    },
    resetSidebar_client() {
      this.tmpItem = { licenceType: null }
    },
    resetSidebar_billing() {
      this.year_selected = new Date().getFullYear()
      this.items_billing.splice(0)
    },
  },
  computed: {
    totalRows() {
      return this.items_client ? this.items_client.length : 0
    },
    totalUsersInMonthRows() {
      return this.tableUtentiItemsInMonth
        ? this.tableUtentiItemsInMonth.length
        : 0
    },
    totalInvoicesRows() {
      return this.invoicesItems ? this.invoicesItems.length : 0
    },

    totale_computed() {
      let totale = 0
      this.items_client.forEach((client) => {
        if (client.TotalPrice) {
          totale = totale + client.TotalPrice
        } else {
          totale
        }
      })
      return totale.toFixed(2)
    },
    utenti_computed() {
      let totale = 0
      this.items_client.forEach((client) => {
        if (client.Users) {
          totale = totale + client.Users
        } else {
          totale
        }
      })
      return totale
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1
      },
    },
  },
}
</script>
